import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'John - Engineer & Technophile',
  lang: 'en',
  description: 'Welcome',
};

// HERO DATA
export const heroData = {
  title: "Hi! I'm",
  name: 'John.',
  subtitle: 'Engineer - Technophile - Consultant ',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'john-h-headshot.png',
  paragraphOne: 'OPEN TO NEW PROJECTS',
  paragraphTwo:
    'I consult with businesses of all sizes to integrate new technology into their workflow. By offering training and technical support I provide an ongoing and lasting relationship. Recently consulting on search engine optimization (SEO), improving search engine ranking, interpreting data analytics and trends. I am availble to write custom code for your website, plugin, and provide maintenance to an already established one. I wrote multiple custom automation scripts to make my shopping and permit purchasing experience easier and quicker.',
  paragraphThree:
    'I am a trained full-stack engineer interested in full-time engineering work as well as technology consulting. Download my engineering resume and see what tools and technologies I have used. ',
  resume: '/john-hill-resume-software-engineer.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'pocket-tarp-v1-landing.png',
    title: 'Blender - Pocket Tarp',
    info: 'Utilized Blender 3D creation suite to render images for Pocket Tarp. Designed, modeled, sculpted, and painted initial renderings. Built product launch demo website.',
    info2:
      "Always up for a new challenge especially when it's at the intersection of art and technology.",
    url: 'https://pocket-tarp.netlify.app/',
    repo: 'https://github.com/johnhill13', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'photo-darkroom-homepage-2021.png',
    title: 'Web Consulting - Photo Darkroom',
    info: "Consulted on PDR's web presence and their technology usage. Discovery revealed Square to be best option for the in store transactions, appointments, loyalty, as well as offering a synced inventory management system for the web presence. Integrating the store items with Weebly was a breeze and ensured owners had full autonomy over the inventory. Setup Instagram and Facebook stores allowing a wider range of customers to shop.",
    info2:
      "This setup realized Photo Darkroom's goal which was to have full autonomy over their inventory, web content, and SEO. Improved visibility to more customers via Facebook and Instagram was a by product of a single self managed inventory system.",
    url: 'https://www.photo-darkroom.com',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'reddit-photomarket.png',
    title: 'Reddit Photomarket Re-design',
    info: "After spending way too much of my time on the r/photomarket looking at different camera gear I grew tired of all the clicking I was doing. I tapped into reddit's API and was able to create something customized and extremely simple which would meet my needs. During development I discovered what I wanted was a custom alert for when certain items appeared. Here is the progress I made on simplifying the visibility on the front-end.",
    info2:
      "I actively use it to notify me for my personal shopping needs, this link may or may not be active and I cannot promise it's not private.",
    url: 'https://github.com/johnhill13/photomarketplace-v2',
    repo: '', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: '', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: 'Interested in working together? Awesome!',
  btn: '',
  email: 'hillxjohn@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://www.twitter.com',
    },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: 'https://codepen.io/',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/john-hill-engineering',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/johnhill13',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
